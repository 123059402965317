import * as React from 'react';

const UserContext = React.createContext({
  chapter: 'Lambda Chi Phi',
  email: 'contact@ducrows.com',
  fraternity: 'Alpha Chi Rho',
  location: 'Philadelphia, PA',
  phone: '(XXX) XXX-XXXX',
});

export default UserContext;
