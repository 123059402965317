import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Application from './components/Application';

window.addEventListener('load', () => {
  const rootElement = document.querySelector('.root');
  // Render the React application to the DOM
  ReactDOM.render(
    <BrowserRouter>
      <Application />
    </BrowserRouter>,
    rootElement,
  );
});
