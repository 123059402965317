import React from 'react';
import cx from 'classnames';
import Page from '../Page';
import Section from '../Section';
import ArrowToTopIcon from '../Page/ArrowToTopIcon';

const Undergraduate = (): JSX.Element => (
  <Page
    menuItemProps={[
      {
        icon: (
          <ArrowToTopIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'navigation',
        text: 'Top',
      },
    ]}
  >
    <Section
      className={cx([
        'u-background-color-white',
        'u-color-black',
        'u-font-size-s',
        'u-text-normal',
        'u-text-center',
      ])}
    >
      <p className="u-text-italic">Undergraduate information coming soon</p>
    </Section>
  </Page>
);

export default React.memo(Undergraduate);
