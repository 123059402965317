import cx from 'classnames';
import { Grid /* , List */ } from 'semantic-ui-react';
import React from 'react';
import Section from '../Section';
import UserContext from '../../utils/context';

const Footer = (): JSX.Element => {
  const value = React.useContext(UserContext);

  return (
    <Section
      className="u-background-color-white"
      id="Footer"
    >
      <Grid
        centered
        columns={1}
        padded="horizontally"
      >
        <Grid.Row>
          <Grid.Column>
            <p
              className={cx([
                'u-color-text',
                'u-font-size-s',
                'u-margin-bottom-4xs',
                'u-text-bold',
                'u-text-center',
              ])}
            >
              &Alpha;&Chi;&Rho; - &Lambda;&Chi;&Phi;
            </p>
            <p
              className={cx([
                'u-color-text',
                'u-font-size-xs',
                'u-margin-bottom-0',
                'u-margin-top-0',
                'u-text-center',
              ])}
            >
              { value.location }
            </p>
            {/* <List
              className={cx([
                'u-margin-top-2xs',
                'u-text-center',
                'u-width-full',
              ])}
            >
              <List.Item
                className="u-display-inline"
              >
                <List.Content
                  className="u-font-size-xs u-margin-top-4xs"
                  style={{
                    width: 'inherit',
                  }}
                >
                  <a href="mailto:contact@ducrows.com">
                    { value.email }
                  </a>
                </List.Content>
              </List.Item>
            </List> */}
            <hr
              className={cx([
                'u-horizontal-rule',
                'u-margin-bottom-s',
                'u-margin-top-2xs',
              ])}
            />
            <p
              className={cx([
                'u-color-copyright',
                'u-text-center',
              ])}
            >
              Copyright &copy; DU Crows 1996-2021
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};

export default React.memo(Footer);
