import React from 'react';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import firebase, { firebaseConfig } from '../../utils/firebase';
import Page from '../Page';
import ArrowToTopIcon from '../Page/ArrowToTopIcon';

const Newsletter = (): JSX.Element => {
  const [imageSrc, setImageSrc] = React.useState<undefined | string>(undefined);

  const fetchImageSrc = (): void => {
    if (!firebase) return;
    // Fetch image src
    const storage = getStorage(firebase);
    const imageRef = ref(storage, `gs://${firebaseConfig.storageBucket}/assets/2022-winter-newsletter.pdf`);
    getDownloadURL(imageRef).then((src: string) => {
      setImageSrc(src);
      return null;
    }).catch(() => {
      // Handle any errors
    });
  };
  // Component did mount
  React.useEffect(() => {
    fetchImageSrc();
  }, []);
  // Fetch image on authentication change
  if (firebase) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) fetchImageSrc();
    });
  }

  return (
    <Page
      menuItemProps={[
        {
          icon: (
            <ArrowToTopIcon
              className="u-margin-bottom-3xs"
              fill="white"
              height={24}
            />
          ),
          id: 'navigation',
          text: 'Top',
        },
      ]}
    >
      <html lang="en">
        <body style={{ height: '100vh', width: '100vw' }}>
          <object data={imageSrc} type="application/pdf" style={{ height: '100%', width: '100%' }}>
            <div>No online PDF viewer installed</div>
          </object>
        </body>
      </html>
    </Page>
  );
};

export default React.memo(Newsletter);
