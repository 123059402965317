import React from 'react';
import MenuItem, { MenuItemProps } from '../MenuItem';

const renderMenuItems = (
  itemProps: MenuItemProps[],
  onClick: (event: React.MouseEvent) => void,
): JSX.Element[] => (
  itemProps.map((props) => (
    <MenuItem
      icon={props.icon}
      id={props.id}
      onClick={onClick}
      text={props.text}
    />
  ))
);

export default renderMenuItems;
