import React from 'react';
import Page from '../Page';
import ArrowToTopIcon from '../Page/ArrowToTopIcon';
import GettingInvolved from './GettingInvolved';
import JoinUsOnSlack from './JoinUsOnSlack';

const Graduate = (): JSX.Element => (
  <Page
    menuItemProps={[
      {
        icon: (
          <ArrowToTopIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'navigation',
        text: 'Top',
      },
    ]}
  >
    <GettingInvolved />
    <JoinUsOnSlack />
  </Page>
);

export default React.memo(Graduate);
