import cx from 'classnames';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { Image } from 'semantic-ui-react';
import React from 'react';
import firebase, { firebaseConfig } from '../../../utils/firebase';

const HeaderImage = (): JSX.Element | null => {
  const [imageSrc, setImageSrc] = React.useState<undefined | string>(undefined);

  const fetchImageSrc = (): void => {
    if (!firebase) return;
    // Fetch image src
    const storage = getStorage(firebase);
    const imageRef = ref(storage, `gs://${firebaseConfig.storageBucket}/assets/logo.png`);
    getDownloadURL(imageRef).then((src: string) => {
      setImageSrc(src);
      return null;
    }).catch(() => {
      // Handle any errors
    });
  };
  // Component did mount
  React.useEffect(() => {
    fetchImageSrc();
  }, []);
  // Fetch image on authentication change
  if (firebase) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) fetchImageSrc();
    });
  }

  return imageSrc ? (
    <Image
      centered
      className={cx([
        'u-margin-bottom-s',
      ])}
      size="huge"
      src={imageSrc}
    />
  ) : null;
};

export default HeaderImage;
