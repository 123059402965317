import React from 'react';
import cx from 'classnames';
import { Menu, Sidebar } from 'semantic-ui-react';
import Navigation from './Navigation';
import HamburgerButton from './HamburgerButton';
import { MenuItemProps } from './MenuItem';

import './styles.scss';
import renderMenuItems from './presentation/renderMenuItems';

type PageProps = {
  children: React.ReactNode;
  menuItemProps: MenuItemProps[];
}

const Page = ({
  children,
  menuItemProps,
}: PageProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = (event: React.MouseEvent): void => {
    if (event) {
      event.preventDefault();
    }
    setIsOpen(!isOpen);
  };

  const onClickDimmer = (event: React.MouseEvent): void => {
    if (isOpen === true) {
      toggleMenu(event);
    }
  };

  const onClickMenu = (event: React.MouseEvent): void => toggleMenu(event);

  return (
    <div
      className={cx([
        'ducrows-website-page',
        'u-height-full',
        'u-width-full',
      ])}
    >
      <Navigation />
      <HamburgerButton
        isOpen={isOpen}
        onClickMenuButton={onClickMenu}
      />
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          direction="right"
          icon="labeled"
          inverted
          vertical
          visible={isOpen}
          width="thin"
        >
          { renderMenuItems(menuItemProps, onClickMenu) }
        </Sidebar>
        <Sidebar.Pusher
          dimmed={isOpen}
          onClick={onClickDimmer}
        >
          { children }
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default React.memo(Page);
