import { getAuth, signInAnonymously } from 'firebase/auth';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Graduate from '../Graduate';
import Home from '../Home';
import Undergraduate from '../Undergraduate';
import Donate from '../Donate';
import firebase from '../../utils/firebase';
import Newsletter from '../Newsletter';

const Application = (): JSX.Element => {
  React.useEffect(() => {
    if (!firebase) return;
    // Sign in as an anonymous user
    const auth = getAuth(firebase);
    signInAnonymously(auth);
  }, []);

  return (
    <Routes>
      <Route
        element={<Home />}
        path="/"
      />
      <Route
        element={<Newsletter />}
        path="/newsletter"
      />
      <Route
        element={<Undergraduate />}
        path="/undergraduate"
      />
      <Route
        element={<Graduate />}
        path="/graduate"
      />
      <Route
        element={<Donate />}
        path="/donate"
      />
    </Routes>
  );
};

export default React.memo(Application);
