import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Image from './Image';
import Section from '../../Section';
import UserContext from '../../../utils/context';

const Header = (): JSX.Element => {
  const value = React.useContext(UserContext);

  return (
    <Section
      className="u-background-color-white"
    >
      <Grid
        centered
        columns={1}
      >
        <Grid.Row>
          <Grid.Column>
            <Image />
            <hr />
            <p
              className={cx([
                'u-color-red',
                'u-font-size-xl',
                'u-margin-top-xs',
                'u-text-center',
                'u-text-bold',
                'u-text-italic',
              ])}
            >
              { value.chapter.toUpperCase() }
              &nbsp;CHAPTER
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};

export default React.memo(Header);
