import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

type NavigationItemProps = {
  title: string;
  to: string;
}

const NavigationItem = ({
  title,
  to,
}: NavigationItemProps) => (
  <Link
    className={cx([
      'u-color-white',
      'u-font-size-s',
      'u-padding-s',
    ])}
    to={to}
  >
    { title }
  </Link>
);

export default React.memo(NavigationItem);
