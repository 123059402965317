import React from 'react';
import cx from 'classnames';

type ZelleInfoProps = {
  children: React.ReactNode;
  step: number;
  title: string;
}

const ZelleInfo = ({
  children,
  step,
  title,
}: ZelleInfoProps): JSX.Element => (
  <div
    className={cx([
      'u-align-items-center',
      'u-flex',
      'u-flex-column',
      'u-padding-s',
    ])}
    style={{
      width: '300px',
    }}
  >
    <div
      className={cx([
        'u-background-color-red',
        'u-color-white',
        'u-margin-bottom-xs',
        'u-padding-top-4xs',
        'u-text-bold',
        'u-text-center',
      ])}
      style={{
        borderRadius: '50%',
        height: '30px',
        width: '30px',
      }}
    >
      { step }
    </div>
    <div
      className={cx([
        'u-margin-bottom-3xs',
        'u-text-bold',
      ])}
    >
      { title.toUpperCase() }
    </div>
    <div>{ children }</div>
  </div>
);

export default ZelleInfo;
