import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Page from '../Page';
import About from './About';
import InfoIcon from './InfoIcon';
import EnvelopeIcon from './EnvelopeIcon';
import ArrowToTopIcon from '../Page/ArrowToTopIcon';

const Home = (): JSX.Element => (
  <Page
    menuItemProps={[
      {
        icon: (
          <ArrowToTopIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'navigation',
        text: 'Top',
      },
      {
        icon: (
          <InfoIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'about',
        text: 'About',
      },
      {
        icon: (
          <EnvelopeIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'footer',
        text: 'Contact',
      },
    ]}
  >
    <Header />
    <About />
    <Footer />
  </Page>
);

export default React.memo(Home);
