import React from 'react';
import {
  Button,
  Grid,
  Image,
  Label,
} from 'semantic-ui-react';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import cx from 'classnames';
import firebase, { firebaseConfig } from '../../utils/firebase';
import Section from '../Section';

const JoinUsOnSlack = (): JSX.Element => {
  const [imageSrc, setImageSrc] = React.useState<undefined | string>(undefined);

  const fetchImageSrc = (): void => {
    if (!firebase) return;
    // Fetch image src
    const storage = getStorage(firebase);
    const imageRef = ref(storage, `gs://${firebaseConfig.storageBucket}/assets/slack.png`);
    getDownloadURL(imageRef).then((src: string) => {
      setImageSrc(src);
      return null;
    }).catch(() => {
      // Handle any errors
    });
  };
  // Component did mount
  React.useEffect(() => {
    fetchImageSrc();
  }, []);
  // Fetch image on authentication change
  if (firebase) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) fetchImageSrc();
    });
  }

  return (
    <Section
      className={cx([
        'u-background-color-red',
      ])}
    >
      <Grid
        centered
        columns={2}
        padded="horizontally"
      >
        { imageSrc && (
          <Grid.Row>
            <Button
              onClick={() => window.open('https://join.slack.com/t/axplxphigrads/shared_invite/zt-12q9qspo0-HAvlkDucyIhdKQfD7gVV2A')}
              style={{
                padding: 0,
              }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Label
                  style={{
                    backgroundColor: 'transparent',
                    paddingLeft: 16,
                    paddingRight: 0,
                  }}
                >
                  Join us on
                </Label>
                <Button
                  icon
                  style={{
                    backgroundColor: 'transparent',
                    margin: 0,
                    paddingBottom: 4,
                    paddingLeft: 0,
                    paddingTop: 4,
                  }}
                >
                  <Image alt="Slack" src={imageSrc} size="tiny" />
                </Button>
              </div>
            </Button>
          </Grid.Row>
        ) }
      </Grid>
    </Section>
  );
};

export default React.memo(JoinUsOnSlack);
