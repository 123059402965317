import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Section from '../Section';
import ZelleInfo from './ZelleInfo';
import Page from '../Page';
import ArrowToTopIcon from '../Page/ArrowToTopIcon';
import DonateIcon from './DonateIcon';

const More = (): JSX.Element => (
  <Page
    menuItemProps={[
      {
        icon: (
          <ArrowToTopIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'navigation',
        text: 'Top',
      },
      {
        icon: (
          <DonateIcon
            className="u-margin-bottom-3xs"
            fill="white"
            height={24}
          />
        ),
        id: 'donate',
        text: 'Donate',
      },
    ]}
  >
    <Section
      className="u-background-color-white"
    >
      <Grid
        centered
        columns={2}
        padded="horizontally"
      >
        <Grid.Row>
          <Grid.Column width="14">
            <div
              className={cx([
                'u-color-black',
                'u-font-size-s',
                'u-text-normal',
                'u-text-center',
              ])}
            >
              <div
                className={
                  cx([
                    'u-font-size-l',
                    'u-margin-bottom-m',
                    'u-text-bold',
                  ])
                }
              >
                Donate
              </div>
              <div
                className={cx([
                  'u-font-size-m',
                  'u-margin-bottom-s',
                  'u-text-bold',
                ])}
              >
                to the undergraduate chapter
              </div>
              <div className="u-margin-bottom-m">
                Contributing to the undergraduate chapter is possible via the following methods
                <p className="u-text-italic">Information coming soon</p>
              </div>
              <div
                className={cx([
                  'u-font-size-m',
                  'u-margin-bottom-s',
                  'u-text-bold',
                ])}
              >
                to the graduate chapter
              </div>
              <div className="u-margin-bottom-m">
                Contributing to the graduate chapter is possible via the following methods
              </div>
              <div
                className={
                  cx([
                    'u-font-size-m',
                    'u-text-bold',
                  ])
                }
              >
                via Zelle:
              </div>
              <Grid
                centered
                className="u-margin-bottom-2xs"
                columns={4}
                doubling
                stackable
              >
                <Grid.Row>
                  <ZelleInfo
                    step={1}
                    title="What is Zelle?"
                  >
                    <a
                      href="https://www.zellepay.com/"
                    >
                      Zelle
                    </a>
                    &nbsp;is a fast way to send and receive money with the people and institutions
                    you know and trust. You can find Zelle in the banking app of hundreds
                    of banks and credit unions nationwide. To see if your institution supports
                    Zelle check the list&nbsp;
                    <a href="https://www.zellepay.com/get-started">
                      here
                    </a>
                    .
                  </ZelleInfo>
                  <ZelleInfo
                    step={2}
                    title="Access Zelle"
                  >
                    Get started by enrolling your email address or U.S. mobile number through your
                    mobile banking app or with the Zelle app.
                  </ZelleInfo>
                  <ZelleInfo
                    step={3}
                    title="Pick a person to pay"
                  >
                    Enter&nbsp;
                    <span className="u-text-bold">treasurer.axp1@gmail.com</span>
                    &nbsp;as the recipient.
                  </ZelleInfo>
                  <ZelleInfo
                    step={4}
                    title="Choose the amount"
                  >
                    Enter the amount to send.
                  </ZelleInfo>
                </Grid.Row>
              </Grid>
              <div
                className={cx([
                  'u-font-size-m',
                  'u-margin-bottom-s',
                  'u-text-bold',
                ])}
              >
                via mail in check:
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={{ textAlign: 'left' }}>
                  <b>Payable to:</b>
                  &nbsp;Alpha Chi Rho Fraternity - Lambda Chi
                  <br />
                  <b>Memo:</b>
                  &nbsp;716251860
                  <br />
                  <b>Address:</b>
                  <br />
                  National Bank by Mail
                  <br />
                  P.O. Box 6185
                  <br />
                  Westerville, OH 43086
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  </Page>
);

export default React.memo(More);
