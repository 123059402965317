import React from 'react';
import cx from 'classnames';
import BarsIcon from './BarsIcon';

type HamburgerButtonProps = {
  isOpen: boolean;
  onClickMenuButton: (event: React.MouseEvent) => void;
}

const HamburgerButton = ({
  isOpen,
  onClickMenuButton,
}: HamburgerButtonProps) => (
  <div
    className={cx(
      [
        'u-cursor-pointer',
        'u-position-fixed',
        'u-right-3xs',
        'u-top-3xs',
        'u-z-index-100',
      ],
      isOpen && 'u-display-none',
    )}
    onClick={onClickMenuButton}
    role="presentation"
    style={{
      backgroundColor: 'rgba(47, 47, 47, 0.6)',
      height: '46px',
      width: '49px',
    }}
  >
    <BarsIcon
      className={cx([
        'u-margin-left-xs',
        'u-margin-top-2xs',
      ])}
      fill="white"
      height={21}
    />
  </div>
);

export default React.memo(HamburgerButton);
