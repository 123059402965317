import React from 'react';
import cx from 'classnames';
import NavigationItem from './NavigationItem';

const Navigation = (): JSX.Element => (
  <div
    className={cx([
      'u-flex',
      'u-flex-row',
      'u-justify-content-center',
    ])}
    id="Navigation"
  >
    <NavigationItem
      title="Home"
      to="/"
    />
    <NavigationItem
      title="Newsletter"
      to="/newsletter"
    />
    <NavigationItem
      title="Undergraduate"
      to="/undergraduate"
    />
    <NavigationItem
      title="Graduate"
      to="/graduate"
    />
    <NavigationItem
      title="Donate"
      to="/donate"
    />
  </div>
);

export default React.memo(Navigation);
