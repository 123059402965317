import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Section from '../Section';

const About = (): JSX.Element => (
  <Section
    className="u-background-color-red"
    id="About"
  >
    <Grid
      centered
      columns={2}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column width="10">
          <p
            className={cx([
              'u-color-white',
              'u-font-size-s',
              'u-text-normal',
              'u-text-center',
            ])}
          >
            <p
              className={cx([
                'u-font-size-m',
                'u-text-bold',
              ])}
            >
              About us
            </p>
            <p>
              There are plenty of fantastic reasons why our chapter is unique and awesome.
              We just don&apos;t have the words for them yet.
            </p>
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Section>
);

export default React.memo(About);
