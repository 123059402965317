import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyD457b2j2jbng_w5tK9Q7-4aYZRtHJYvto',
  authDomain: 'ducrows-website.firebaseapp.com',
  projectId: 'ducrows-website',
  storageBucket: 'ducrows-website.appspot.com',
  messagingSenderId: '269109152809',
  appId: '1:269109152809:web:ba4753817e3ece94c0a038',
  measurementId: 'G-G1M83PHZYB',
};

export default initializeApp(firebaseConfig);
