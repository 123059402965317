import React from 'react';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import Section from '../Section';

const GettingInvolved = (): JSX.Element => (
  <Section
    className={cx([
      'u-background-color-white',
      'u-text-center',
    ])}
  >
    <Grid
      centered
      columns={2}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column width="10">
          <p
            className={cx([
              'u-color-black',
              'u-font-size-s',
              'u-text-normal',
              'u-text-center',
            ])}
          >
            <p
              className={cx([
                'u-font-size-m',
                'u-text-bold',
              ])}
            >
              Getting involved
            </p>
            <p>
              Joining the graduate chapter is as easy as ever. No dues, optional meetings,
              maximum brotherhood. Currently we are holding a monthly Zoom meeting to catch up,
              are hosting a Slack workspace for easy communication, and a brave few of us get
              together for video games on Fridays. If you&apos;re interested, let us know below.
            </p>
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <iframe
          className="u-margin-top-3xs"
          frameBorder="0"
          height="1159"
          marginHeight={0}
          marginWidth={0}
          src="https://docs.google.com/forms/d/e/1FAIpQLSfNcmzDWk3GczblbOURvKxKzQNnxLBRYefOioKUIRtHy01xbQ/viewform?embedded=true"
          title="Sign Up Form"
          width="640"
        >
          Loading…
        </iframe>
      </Grid.Row>
      <Grid.Row>
        <p
          className={cx([
            'u-color-black',
            'u-font-size-s',
            'u-margin-top-2xs',
            'u-text-normal',
            'u-text-center',
          ])}
        >
          Or if you&apos;d like to update your information with National, you can do so&nbsp;
          <a href="https://www.alphachirho.org/update-contact-information/" rel="noreferrer" target="_blank">here</a>
          .
        </p>
      </Grid.Row>
    </Grid>
  </Section>
);

export default React.memo(GettingInvolved);
